import React, { useEffect, useState } from 'react'
import { formatAmount } from '../../../utils/products'
import { PriceFieldsFragment } from '../../../graphql'
import { selectIapPrices } from '../../../store/base/commonSlice'
import { useSelector } from 'react-redux'

type ActionButtonsProps = {
  price: PriceFieldsFragment
}

const PricingInfo = ({ price }: ActionButtonsProps) => {
  const [amount, setAmount] = useState<number | null>(null)

  const iapPrices = useSelector(selectIapPrices)

  useEffect(() => {
    const iapPrice = iapPrices.find((p) => p.sku === price.iapSkuIos || p.sku === price.iapSkuAndroid)
    setAmount(iapPrice ? iapPrice.amount * 100 : price.amount)
  }, [])

  return amount ? (
    <div style={styles.productPriceContainer}>
      <span style={styles.productPriceText}>{formatAmount(amount)}</span>
      {price.recurring ? (
        <span style={styles.productIntervalText}>/{price.recurring.interval}</span>
      ) : (
        <span style={styles.productOneTimePurchaseText}>One Time Payment</span>
      )}
    </div>
  ) : null
}

const styles = {
  productPriceContainer: {
    width: '100%',
    marginTop: 45,
    display: 'flex',
    alignItems: 'flex-end',
  } as React.CSSProperties,
  productPriceText: {
    textAlign: 'left',
    fontSize: 32,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 700,
    lineHeight: '27px',
  } as React.CSSProperties,
  productIntervalText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#808080',
    opacity: 1,
    fontWeight: 500,
    marginLeft: 2,
  } as React.CSSProperties,
  productOneTimePurchaseText: {
    fontSize: 17,
    letterSpacing: 0,
    color: '#808080',
    opacity: 1,
    fontWeight: 500,
    marginLeft: 'auto',
  } as React.CSSProperties,
}

export default PricingInfo
