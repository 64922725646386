import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectNativeAppMessage, setIapPrices } from '../store/base/commonSlice'
import { sendNativeAppMessage } from '../utils/nativeAppMessage'
import {
  IapInterval,
  IapPlatform,
  Product,
  ProductResponseSuccess,
  useProductsQuery,
  useRecordIapMutation,
} from '../graphql'
import { useNavigate } from 'react-router-dom'

const IapListener = () => {
  let didRetry = useRef(false)
  let priceRequestData = useRef<{ sku: string; isSubscription: boolean }[]>([])

  const isNativeApp = 'isNativeApp' in window && !!window.isNativeApp
  const nativeAppPlatform = isNativeApp && 'nativeAppPlatform' in window ? window.nativeAppPlatform : null

  const dispatch = useDispatch()
  const nativeAppMessage = useSelector(selectNativeAppMessage)

  const navigate = useNavigate()

  const [recordIap] = useRecordIapMutation()

  const { data: productsData } = useProductsQuery({
    variables: {
      data: {},
    },
  })

  useEffect(() => {
    if (!productsData || !isNativeApp) {
      return
    }
    priceRequestData.current = []
    const _productsData = productsData.products as ProductResponseSuccess
    const products = _productsData.list as Product[]
    products.forEach((product) => {
      product.prices.forEach((price) => {
        priceRequestData.current.push({
          sku: nativeAppPlatform === 'ios' ? price.iapSkuIos || '' : price.iapSkuAndroid || '',
          isSubscription: !!price.recurring,
        })
      })
    })
    sendNativeAppMessage({
      requestPrices: priceRequestData.current,
    })
  }, [productsData])

  useEffect(() => {
    if (nativeAppMessage?.iapResult) {
      sendNativeAppMessage({ message: 'RECEIVED BY WEB APP: ' + JSON.stringify(nativeAppMessage) })
      const iapResult = nativeAppMessage.iapResult
      if (iapResult.success) {
        recordIap({
          variables: {
            data: {
              platform: iapResult.platform as IapPlatform,
              sku: iapResult.sku,
              amount: iapResult.amount,
              recurring: iapResult.recurring,
              interval: iapResult.interval as IapInterval,
              contentId: iapResult.contentId,
              bundleId: iapResult.bundleId,
            },
          },
        }).then(() => {
          navigate(`/content/${iapResult.contentId}`)
        })
      }
    } else if (nativeAppMessage?.skuPrices) {
      if (!nativeAppMessage.skuPrices.length && !didRetry.current) {
        sendNativeAppMessage({
          requestPrices: priceRequestData.current,
        })
        didRetry.current = true
      }
      dispatch(setIapPrices(nativeAppMessage.skuPrices))
    }
  }, [nativeAppMessage])

  return null
}

export default IapListener
