import { ContentRestrictionsType, GateType, MediaItemFieldsFragment, Price } from '../graphql'
import { formatAmount, formatInterval } from './products'
import store from '../store'

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine: true,
): { text: string; type?: string }

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine?: false,
): {
  paywallInfo: { text: string; type?: string }[]
  skipFirstLine: boolean
}

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine = false,
):
  | { text: string; type?: string }
  | {
      paywallInfo: { text: string; type?: string }[]
      skipFirstLine: boolean
    } {
  const paywallInfo: { text: string; type?: string }[] = []
  let skipFirstLine = false

  if (!mediaItem?.restrictions?.length) {
    if (singleLine) {
      return { text: '' }
    }
    return {
      paywallInfo,
      skipFirstLine,
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Ppv)) {
    const ppvGates = mediaItem.appliedGates.filter((g) => g.type === GateType.Ppv)

    const cheapestPpvGate = ppvGates
      .sort((a, b) => ((a.product?.defaultPrice?.amount || 0) > (b.product?.defaultPrice?.amount || 0) ? 1 : -1))
      .find((g) => g)

    const defaultPrice = cheapestPpvGate?.product?.defaultPrice

    const iapAmount = getIapAmount(defaultPrice as Price)
    const cheapestPrice = formatAmount(iapAmount >= 0 ? iapAmount * 100 : defaultPrice?.amount)

    paywallInfo.push({ text: `Available on-demand ${cheapestPrice ? `for ${cheapestPrice}` : ''}`, type: 'ppv' })

    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Bundle)) {
    paywallInfo.push({ text: 'Available as part of a bundle', type: 'bundle' })
    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Subscription)) {
    const subscriptionGates = mediaItem.appliedGates
      .filter((g) => g.type === GateType.Subscription)
      .sort((a, b) => ((a.product?.defaultPrice?.amount || 0) > (b.product?.defaultPrice?.amount || 0) ? 1 : -1))

    if (singleLine && mediaItem.appliedGates.length > 1) {
      const cheapestGate = subscriptionGates[0]
      const defaultPrice = cheapestGate?.product?.defaultPrice

      const iapAmount = getIapAmount(defaultPrice as Price)
      const cheapestPrice = formatAmount(iapAmount >= 0 ? iapAmount * 100 : defaultPrice?.amount)
      const interval = formatInterval(defaultPrice?.recurring?.interval)
      return {
        text: `Available ${cheapestPrice && interval ? `starting at ${cheapestPrice}/${interval}` : 'by subscription'}`,
        type: 'subscription',
      }
    }

    if (!paywallInfo.length && subscriptionGates.length > 1) {
      skipFirstLine = true
    }

    subscriptionGates.forEach((gate) => {
      const iapAmount = getIapAmount(gate.product?.defaultPrice as Price)
      const price = formatAmount(iapAmount >= 0 ? iapAmount * 100 : gate.product?.defaultPrice?.amount)
      const interval = formatInterval(gate.product?.defaultPrice?.recurring?.interval)
      paywallInfo.push({
        text: `${gate.product?.name}${
          mediaItem.appliedGates.length === 1 && mediaItem.bundles.length === 0 ? ' Exclusive' : ''
        } · ${price}/${interval}`,
        type: 'subscription',
      })
    })

    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Registration)) {
    paywallInfo.push({ text: 'Sign in or register for an account to view this content.', type: 'registration' })
    if (singleLine) return paywallInfo[0]
  }

  return {
    paywallInfo,
    skipFirstLine,
  }
}

const getIapAmount = (price?: Price | null): number => {
  const isNativeApp = 'isNativeApp' in window && !!window.isNativeApp
  const nativeAppPlatform = isNativeApp && 'nativeAppPlatform' in window ? window.nativeAppPlatform : null

  if (!price || !isNativeApp) {
    return 0
  }

  const iapPrices: { sku: string; amount: number }[] = store.getState().base?.common?.iapPrices

  let iapAmount = -1
  let iapSku: string | null | undefined = null

  if (nativeAppPlatform === 'ios') {
    iapSku = price.iapSkuIos
  } else {
    iapSku = price.iapSkuAndroid
  }
  if (iapSku && iapPrices?.length) {
    iapAmount = iapPrices.find((p) => p.sku === iapSku)?.amount || -1
  }

  return iapAmount
}

export default getPaywallText
