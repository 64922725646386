import React from 'react'
import './index.css'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { GateType, PriceFieldsFragment, useCreateCheckoutSessionMutation, useMediaItemQuery } from '../../graphql'
import { WindowSize } from '../../constants/enums'
import PpvInfo from './components/PpvInfo'
import SubscriptionInfo from './components/SubscriptionInfo'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize } from '../../store/base/commonSlice'
import PricingInfo from './components/PricingInfo'
import checkSubscription from '../../utils/checkSubscription'
import { selectUser } from '../../store/auth/userSlice'
import LoadingScreen from '../../common/LoadingScreen'
import { sendNativeAppMessage } from '../../utils/nativeAppMessage'
import BundleInfo from './components/BundleInfo'

const Products = () => {
  let productType = location.pathname.split('/')[1]

  const params = useParams()
  const user = useSelector(selectUser)
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)

  const isNativeApp = 'isNativeApp' in window && !!window.isNativeApp
  const nativeAppPlatform = isNativeApp && 'nativeAppPlatform' in window ? window.nativeAppPlatform : null

  const contentId = params?.contentId
  if (!contentId) return null
  // const productId = params?.productId - unused for now, but could be used in the future for highlighting a subscription without navigating from content

  const navigate = useNavigate()

  const { data, loading } = useMediaItemQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        id: contentId,
      },
    },
  })

  const [createCheckoutSessionMutation] = useCreateCheckoutSessionMutation()

  const mediaItem = data?.mediaItem

  const filteredGates = mediaItem?.appliedGates.filter(
    (gate) => gate.type === (productType === 'purchase' ? GateType.Ppv : GateType.Subscription) && gate.product,
  )

  if (mediaItem) {
    if (mediaItem.purchased) {
      navigate(`/content/${contentId}`)
    }
    if (productType === 'subscribe' && checkSubscription(user, mediaItem)) {
      navigate(`/content/${contentId}`)
    }
  }

  const containerClassName = !filteredGates || filteredGates.length === 1 ? 'productContainer' : ''
  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand

  const goToStripe = async (price: PriceFieldsFragment | undefined, bundleId?: string) => {
    if (!price) return // todo: handle error
    const redirectUrlPathname = contentId
      ? productType === 'purchase'
        ? `/purchase/verify?contentId=${contentId}${bundleId ? `&bundleId=${bundleId}` : ''}${
            price?.id ? `&priceId=${price?.id}` : ''
          }&checkoutSessionId={CHECKOUT_SESSION_ID}`
        : `/content/${contentId}`
      : '/'
    const { data: _data } = await createCheckoutSessionMutation({
      variables: {
        data: {
          priceId: price.id,
          contentId,
          bundleId,
          redirectUrl: location.origin + redirectUrlPathname,
        },
      },
    })
    if (_data?.createCheckoutSession?.url) {
      window.location.href = _data.createCheckoutSession.url
    } else {
      // todo: handle error
    }
  }

  const onPressPurchase = (price: PriceFieldsFragment | undefined, bundleId?: string) => {
    const iosSku = price?.iapSkuIos
    const androidSku = price?.iapSkuAndroid

    if ((iosSku || androidSku) && isNativeApp) {
      sendNativeAppMessage({
        startIap: true,
        iapSkuIos: iosSku || undefined,
        iapSkuAndroid: androidSku || undefined,
        isConsumable: true,
        isSubscription: false,
        contentId,
        bundleId,
      })
      return
    }
    goToStripe(price, bundleId)
  }

  if (data) {
    console.log(data)
  }

  const iapAvailable = (price: PriceFieldsFragment | null) => {
    return nativeAppPlatform === 'ios' ? !!price?.iapSkuIos : !!price?.iapSkuAndroid
  }

  const anyFilteredGateIapsAvailable = filteredGates?.some((gate) => iapAvailable(gate.product?.defaultPrice || null))

  const anyBundleIapsAvailable = mediaItem?.bundles?.some((bundle) =>
    iapAvailable(bundle.product?.defaultPrice || null),
  )

  return (
    <LoadingScreen loading={loading}>
      <div className={containerClassName} style={styles.container}>
        <div style={brandStyle}>
          <img src={brand?.icon?.src} alt="brand" style={styles.logo} onClick={() => navigate('/')} />
        </div>

        <div className={'productSlide'} style={styles.productSlide}>
          <div style={styles.productHeaderContainer}>
            <span style={styles.productHeader}>{productType.toUpperCase()}</span>
            <div style={styles.productHeaderDivider} />
          </div>
          {filteredGates
            ?.map((gate, index) => {
              const productInputFormStyle =
                index < filteredGates.length - 1
                  ? { ...styles.productInputForm, marginBottom: 35 }
                  : styles.productInputForm
              return gate.product && gate.product.defaultPrice ? (
                <div key={index} style={productInputFormStyle}>
                  {productType === 'purchase' && mediaItem ? (
                    <PpvInfo mediaItem={mediaItem} />
                  ) : (
                    <SubscriptionInfo product={gate.product} />
                  )}
                  {isNativeApp && !iapAvailable(gate.product?.defaultPrice) ? (
                    anyBundleIapsAvailable ? null : (
                      <div style={styles.iapMessage}>Content cannot be purchased within the app</div>
                    )
                  ) : (
                    <>
                      <PricingInfo price={gate.product.defaultPrice} />
                      <div style={{ marginTop: 30 }}>
                        <Button
                          text={`${productType === 'purchase' ? 'buy' : 'subscribe'} now`}
                          width={'100%'}
                          backgroundColor={'#FFFFFF'}
                          height={36}
                          onClick={() =>
                            onPressPurchase(gate.product?.defaultPrice ? gate.product.defaultPrice : undefined)
                          }
                        />
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          text={params?.contentId ? 'back' : 'not now'}
                          width={'100%'}
                          height={36}
                          arrowLeft={!!params?.contentId}
                          onClick={() => navigate(contentId ? `/content/${contentId}` : '/')}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : null
            })
            .filter((gate) => gate)}
          {mediaItem?.bundles && mediaItem?.bundles.length && (
            <>
              {!filteredGates?.length && <PpvInfo mediaItem={mediaItem} />}
              <div style={{ ...styles.bundleWrapper, paddingTop: filteredGates?.length ? 40 : 28 }}>
                {(filteredGates?.length && !isNativeApp) ||
                (filteredGates?.length && anyFilteredGateIapsAvailable && anyBundleIapsAvailable && isNativeApp) ? (
                  <div style={styles.orWrapper}>
                    <span style={styles.divider} />
                    <span>OR</span>
                    <span style={styles.divider} />
                  </div>
                ) : null}
                <div style={styles.bundleHeader}>Buy as part of a bundle:</div>
                <div style={styles.bundleInfoWrapper}>
                  {mediaItem?.bundles.map((bundle, _index) => {
                    return (
                      <BundleInfo
                        showIapUnavailableMessage={isNativeApp && !iapAvailable(bundle.product?.defaultPrice || null)}
                        onClickBuy={() => onPressPurchase(bundle.product?.defaultPrice || undefined, bundle.id)}
                        key={_index}
                        isDropdown={mediaItem.bundles.length > 1}
                        title={bundle.title}
                        priceData={bundle.product?.defaultPrice}
                        description={bundle.description || ''}
                        includedItemTitles={bundle.items.map((item) => item.name)}
                      />
                    )
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </LoadingScreen>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 17,
  } as React.CSSProperties,
  logoWrapper: {
    display: 'flex',
    marginLeft: 40,
    marginTop: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 65,
    width: 65,
  } as React.CSSProperties,
  brand: {
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  mobileBrand: {
    height: 40,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  logo: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  productSlide: {
    maxWidth: 350,
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 45,
  } as React.CSSProperties,
  productHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 30,
  } as React.CSSProperties,
  productHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  productHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  productInputForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  orWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 40,
    color: 'white',
    fontWeight: '500',
    font: 'SF Pro Text',
    fontSize: 13,
    lineHeight: '153%',
    letterSpacing: 2.75,
  } as React.CSSProperties,
  bundleWrapper: {
    textAlign: 'left',
    paddingBottom: 40,
  } as React.CSSProperties,
  bundleHeader: {
    font: 'SF Pro Text',
    color: 'white',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '125%',
    marginBottom: 8,
  } as React.CSSProperties,
  bundleInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  } as React.CSSProperties,
  divider: {
    height: 1,
    flex: 1,
    backgroundColor: '#404040',
  } as React.CSSProperties,
  iapMessage: {
    marginTop: 20,
    font: 'SF Pro Text',
    color: 'white',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '125%',
    textAlign: 'left',
  } as React.CSSProperties,
}

export default Products

/*
<div style={styles.productInputForm}>
          <span style={styles.productNameText}>IGNATIUS+</span>
          <span style={{ ...styles.productMiscText, marginBottom: 20 }}>
            Description text ultrices neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae
            proin.
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Ultrices neque ornare
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Aenean euismod elementum
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Nisi quis eleifend
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Quam adipiscing vitae
          </span>
          <div style={styles.productPriceContainer}>
            <span style={styles.productPriceText}>$4.50</span>
            <span style={styles.productIntervalText}>/month</span>
          </div>
          <div style={{ marginTop: 30 }}>
            <Button
              text={'subscribe now'}
              width={'100%'}
              backgroundColor={'#FFFFFF'}
              height={36}
              onClick={() => navigate('/')}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              text={path ? 'back' : 'not now'}
              width={'100%'}
              height={36}
              arrowLeft={!!path}
              onClick={() => (path ? navigate(path, { state: { ...location?.state } }) : navigate('/'))}
            />
          </div>
        </div>
 */
