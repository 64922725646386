import React, { useEffect, useState } from 'react'
import { ReactComponent as TVIcon } from '../../../assets/TVIcon.svg'
import { ReactComponent as StackIcon } from '../../../assets/StackIcon.svg'
import { ReactComponent as DropdownArrow } from '../../../assets/DropdownArrow.svg'
import Button from '../../../common/buttons/Button'
import { PriceFieldsFragment } from '../../../graphql'
import { useSelector } from 'react-redux'
import { selectIapPrices } from '../../../store/base/commonSlice'

export type BundleInfoProps = {
  title: string
  onClickBuy: () => void
  priceData?: PriceFieldsFragment | null
  description?: string
  subscription?: boolean
  priceType?: string
  includedItemTitles?: string[]
  isDropdown?: boolean
  showIapUnavailableMessage?: boolean
}

const BundleInfo = ({
  title,
  description,
  priceData,
  subscription = false,
  includedItemTitles = [],
  isDropdown = false,
  onClickBuy,
  showIapUnavailableMessage = false,
}: BundleInfoProps) => {
  const [isExpanded, setIsExpanded] = useState(!isDropdown)
  const [displayPrice, setDisplayPrice] = useState<number | null>(null)

  const priceAmount = priceData?.amount ? priceData.amount / 100 : null

  const iapPrices = useSelector(selectIapPrices)

  useEffect(() => {
    const iapPrice = iapPrices.find((p) => p.sku === priceData?.iapSkuIos || p.sku === priceData?.iapSkuAndroid)
    setDisplayPrice(iapPrice?.amount || priceAmount)
  }, [])

  return (
    <div
      onClick={() => {
        if (isDropdown && !isExpanded) {
          setIsExpanded(true)
        }
      }}
      style={{
        ...styles.container,
        cursor: isDropdown && !isExpanded ? 'pointer' : 'default',
        paddingTop: 16,
      }}>
      <div
        onClick={() => {
          if (isDropdown) {
            setIsExpanded(!isExpanded)
          }
        }}
        style={{
          ...styles.topWrapper,
          cursor: isDropdown ? 'pointer' : 'default',
        }}>
        <div style={styles.titleTextWrapper}>
          <span style={styles.title}>{title}</span>
          <span style={styles.bundleSize}>{`(${includedItemTitles?.length || 0} videos)`}</span>
        </div>
        {isDropdown && (
          <>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                opacity: isExpanded ? 0 : 1,
                transition: 'opacity 250ms',
              }}>
              <DropdownArrow />
            </div>
            <div
              style={{
                position: 'absolute',
                transform: 'rotateX(180deg)',
                top: 4,
                right: 0,
                opacity: isExpanded ? 1 : 0,
                transition: 'opacity 250ms',
              }}>
              <DropdownArrow />
            </div>
          </>
        )}
      </div>
      <div style={{ height: isExpanded ? '100%' : 0 }}>
        {description && <div style={styles.description}>{description}</div>}
        {showIapUnavailableMessage ? (
          <div style={styles.purchaseNote}>Content cannot be purchased within the app</div>
        ) : (
          <>
            {displayPrice && (
              <div style={styles.priceWrapper}>
                <div style={styles.price}>${displayPrice}</div>
                <div style={styles.purchaseType}>{subscription ? '' : 'One-Time Purchase'}</div>
              </div>
            )}
            <div style={styles.button}>
              <Button onClick={onClickBuy} backgroundColor={'#FFFFFF'} width={'100%'} height={42}>
                <span style={styles.stackIcon}>
                  <StackIcon />
                </span>
                Buy Video Bundle
              </Button>
            </div>
            <div style={styles.purchaseNote}>
              By purchasing, you’ll unlock access to stream the videos below. They will be located in My Library under
              Purchases.
            </div>
          </>
        )}
        <div style={{ width: '100%', paddingBottom: 16 }}>
          <div style={styles.includesWrapper}>
            <span>INCLUDES</span>
            <div style={styles.divider}></div>
          </div>
          {includedItemTitles.map((itemTitle, index) => (
            <div style={styles.includedItemWrapper} key={index}>
              <TVIcon />
              {itemTitle}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    maxWidth: 350,
    width: '100%',
    backgroundColor: 'rgba(64, 64, 64, 0.20)',
    padding: 16,
    paddingBottom: 0,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#404040',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  } as React.CSSProperties,
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 16,
    position: 'relative',
  } as React.CSSProperties,
  titleTextWrapper: {
    flex: 1,
  },
  bundleSize: {
    color: '#818181',
    font: 'SF Pro Text',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '125%',
    marginLeft: 4,
  },
  title: {
    color: 'white',
    font: 'SF Pro Text',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '125%',
  } as React.CSSProperties,
  description: {
    color: 'white',
    font: 'SF Pro Text',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '125%',
    marginTop: 4,
    marginBottom: 24,
  } as React.CSSProperties,
  priceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%',
  } as React.CSSProperties,
  price: {
    color: 'white',
    font: 'SF Pro Text',
    fontSize: 26,
    fontWeight: 700,
  } as React.CSSProperties,
  purchaseType: {
    color: '#818181',
    font: 'SF Pro Text',
    fontSize: 13,
    fontWeight: 600,
    alignSelf: 'flex-end',
  } as React.CSSProperties,
  button: {
    width: '100%',
    marginBottom: 16,
  },
  stackIcon: {
    color: '#215198',
    marginRight: 8,
    marginTop: 4,
  },
  purchaseNote: {
    color: '#C1C1C1',
    font: 'SF Pro Text',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '153%',
    marginBottom: 24,
  },
  includesWrapper: {
    width: '100%',
    color: 'white',
    letterSpacing: 2.75,
    lineHeight: '153%',
    fontWeight: 500,
    font: 'SF Pro Text',
    fontSize: 13,
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  divider: {
    width: '100%',
    height: 1,
    flex: 1,
    backgroundColor: '#404040',
  },
  includedItemWrapper: {
    color: '#C1C1C1',
    fontSize: 16,
    font: 'SF Pro Text',
    fontWeight: 500,
    lineHeight: '125%',
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}

export default BundleInfo
