import { createSelector, createSlice } from '@reduxjs/toolkit'
import { WindowSize } from '../../constants/enums'
import { LinkFieldsFragment } from '../../graphql'

type CommonState = {
  currentRouteKey: string
  windowSize: WindowSize
  brandData?: LinkFieldsFragment
  header: 'normal' | 'search'
  nativeAppMessage: NativeAppMessageReceiveData | null
  iapPrices: {
    sku: string
    amount: number
  }[]
}

export const initialState: CommonState = {
  currentRouteKey: '',
  windowSize: WindowSize.DesktopFull,
  brandData: undefined,
  header: 'normal',
  nativeAppMessage: null,
  iapPrices: [],
}

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload
    },
    setWindowSize: (state, action) => {
      state.windowSize = action.payload
    },
    setBrandData: (state, action) => {
      state.brandData = action.payload
    },
    setHeader: (state, action) => {
      state.header = action.payload
    },
    setNativeAppMessage: (state, action) => {
      state.nativeAppMessage = action.payload
    },
    setIapPrices: (
      state,
      action: {
        payload: {
          sku: string
          amount: number
        }[]
      },
    ) => {
      state.iapPrices = action.payload
    },
  },
})

export const selectWindowSize = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.windowSize
  },
  (windowSize) => windowSize,
)

export const selectBrandData = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.brandData
  },
  (brandData) => brandData,
)

export const selectHeader = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.header
  },
  (header) => header,
)

export const selectNativeAppMessage = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.nativeAppMessage
  },
  (nativeAppMessage) => nativeAppMessage,
)

export const selectIapPrices = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.iapPrices
  },
  (iapPrices) => iapPrices,
)

export const { setCurrentRouteKey, setWindowSize, setBrandData, setHeader, setNativeAppMessage, setIapPrices } =
  commonSlice.actions

export default commonSlice.reducer
